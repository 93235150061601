import styled from 'styled-components';
import tw from 'tailwind.macro';
import { math, rem } from 'polished';

export const BigButton = styled.span`
  ${tw`font-serif inline-block italic mt-16 relative text-base text-purple-300 uppercase`};
  line-height: ${math('18/14')};

  &::after {
    ${tw`absolute bg-purple-300 left-0 w-full`};
    bottom: ${rem('-1px')};
    content: '';
    height: 2px;
  }
`;

export const Button = styled.span`
  ${tw`font-serif inline-block italic mt-16 relative text-sm text-purple-300 uppercase`};
  line-height: ${math('18/14')};

  &::after {
    ${tw`absolute bg-purple-300 left-0 w-full`};
    bottom: ${rem('-1px')};
    content: '';
    height: 2px;
  }
`;
