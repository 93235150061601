import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Wrapper } from '../elements/Wrappers';
import { Column, Container, Row } from '../elements/Grid';
import { BaseTitle, LgTitle, SmTitle, XsTitle } from '../elements/Titles';
import { ImageDesktopWrapper, ImageWrapper } from '../elements/Home';
import {
  BaseParagraph,
  XlParagraph,
  XsParagraph
} from '../elements/Paragraphs';
import { BigButton, Button } from '../elements/Button';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Home_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      <Wrapper>
        <Container>
          <Row marginBottom="0">
            <Column width="5/12">
              <LgTitle>
                Wise Publishing proudly operates MoneyWise.com, Money.ca & MoneyWise.co.uk.
              </LgTitle>
              <ImageDesktopWrapper>
                <Img fluid={data.file.childImageSharp.fluid} />
              </ImageDesktopWrapper>
            </Column>
            <Column width="1/12" />
            <Column width="1/2">
              <BaseTitle>
                Digital personal finance publications with 10 million monthly
                unique readers.
              </BaseTitle>
            </Column>
            <Column>
              <ImageWrapper>
                <Img fluid={data.file.childImageSharp.fluid} />
              </ImageWrapper>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Wrapper dark="true">
        <Container>
          <Row marginBottom="0">
            <Column width="7/12">
              <SmTitle>About Us</SmTitle>
              <XsParagraph>
                The Wise Publishing team consists of homeowners, recent
                graduates, parents, newlyweds and investors saving for
                retirement, so we get it. We know how important it is to
                understand personal finance.
              </XsParagraph>
              <XsParagraph>
                Everything we do is underscored by a commitment to helping{' '}
                <a
                  href="https://moneywise.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MoneyWise.com
                </a>{' '}
                readers make sense of complex topics and get ahead.
              </XsParagraph>
              <XsParagraph>
                Wise Publishing is headquartered in Toronto, Canada.
              </XsParagraph>
              <Link to="/contact/">
                <Button>Contact Us</Button>
              </Link>
            </Column>
            <Column width="1/12" />
            <Column width="1/3">
              <SmTitle>Careers</SmTitle>
              <XsParagraph>
                A career at Wise Publishing positions you at the forefront of
                innovation in digital media. Wise Publishing offers a friendly,
                fun, inspiring and fast-paced environment. Each day at Wise
                Publishing is different than the one before.
              </XsParagraph>
              <XsParagraph>
                Learn more about what life at Wise Publishing looks like.
              </XsParagraph>
              <Link to="/careers/">
                <Button>View Careers</Button>
              </Link>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Container>
        <Row marginBottom="144">
          <Column width="1/4">
            <XsTitle>Latest News</XsTitle>
          </Column>
          <Column width="3/4">
            <XlParagraph>
              Read the latest press releases and news from Wise Publishing.
            </XlParagraph>
            <Link to="/news/">
              <BigButton>Learn More</BigButton>
            </Link>
          </Column>
        </Row>
        <Row marginBottom="144">
          <Column width="1/4">
            <XsTitle>Advertising Solutions</XsTitle>
          </Column>
          <Column width="3/4">
            <XlParagraph>
              Wise Publishing has earned the trust of its readers by meeting
              them in the right context — with respect and intent.
            </XlParagraph>
            <BaseParagraph>
              Our premium suite of advertising solutions builds upon this
              principle, improving audience experience and brand results.
            </BaseParagraph>
            <BaseParagraph>
              We encourage you to contact us if you wish to advertise on a Wise
              Publishing digital property.
            </BaseParagraph>
            <Link to="/contact/">
              <BigButton>Contact Us</BigButton>
            </Link>
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};

export default IndexPage;
