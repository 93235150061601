import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Wrapper = styled.div`
  ${tw`mb-48 md:mb-144 py-64 md:py-144`};
  ${props => props.dark && tw`bg-purple-100`}

  h1 {
    ${tw`mt-6`};
  }
`;
