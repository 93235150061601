import styled from 'styled-components';
import tw from 'tailwind.macro';
import { rem } from 'polished';

import { theme } from '../../tailwind.config.js';

export const ImageDesktopWrapper = styled.div`
  ${tw`hidden`};
  @media (min-width: ${theme.screens.md}) {
    ${tw`absolute block`};
    top: ${rem('432px')};
    width: ${rem('336px')};
  }
  @media (min-width: ${theme.screens.lg}) {
    top: ${rem('240px')};
    width: ${rem('480px')};
  }
`;

export const ImageWrapper = styled.div`
  @media (min-width: ${theme.screens.md}) {
    ${tw`hidden`};
  }
`;
